import httpUtil from "@/utils/httpUtil";

//用户钱包流水
export function getUserWalletRecordList(params) {
    return httpUtil.post("/api/crmPc/userWallet/getUserWalletRecordList", params);
}
//用户积分记录
export function selectAllPointRecord(params) {
    return httpUtil.post("/api/crmPc/member/selectAllPointRecord", params);
}
//新增或修改会员积分发放计划
export function setMemberPointProject(params) {
    return httpUtil.post("/api/crmPc/member/setMemberPointProject", params);
}
//查询会员积分发放计划列表
export function getMemberPointProjectList(params) {
    return httpUtil.post("/api/crmPc/member/getMemberPointProjectList", params);
}
//删除会员积分发放计划
export function delMemberPointProject(params) {
    return httpUtil.post("/api/crmPc/member/delMemberPointProject", params);
}