<template>
    <div class="view pa24">
        <div class="d-flex">
            <div class="operation-right d-flex">
                <el-radio-group class="mr20 d-flex align-items-center" @change="searchFun"
                    v-model="searchData.pointRecordType">
                    <el-radio-button label="all">全部</el-radio-button>
                    <el-radio-button label="0">增加</el-radio-button>
                    <el-radio-button label="1">扣除</el-radio-button>
                </el-radio-group>
                <el-input v-model="searchData.pointRecordName" class="mr20 d-flex align-items-center"
                    placeholder="请输入积分描述"></el-input>
                <el-button style="height: 32px" type="primary" @click="searchFun">查询</el-button>
            </div>
        </div>
        <el-tabs v-model="accountType" @tab-click="searchFun">
            <el-tab-pane label="名片端" name="1"></el-tab-pane>
            <el-tab-pane label="园区端" name="8"></el-tab-pane>
            <el-tab-pane label="社团端" name="9"></el-tab-pane>
        </el-tabs>
        <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
            <template v-slot:table>
                <el-table-column type="index" align="center" label="序号" />
                <el-table-column prop="nickeName" align="center" label="会员昵称" width="120" show-overflow-tooltip />
                <el-table-column prop="pointRecordName" align="center" label="积分描述" show-overflow-tooltip />
                <el-table-column show-overflow-tooltip prop="pointNum" align="center" label="积分" width="100">
                    <template slot-scope="scope">
                        <div>
                            <div style="color: #67c23a" v-if="scope.row.pointRecordType == 0">
                                +{{ scope.row.pointNum }}
                            </div>
                            <div style="color: #f56c6c" v-if="scope.row.pointRecordType == 1">
                                -{{ scope.row.pointNum }}
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="createTime" align="center" label="操作日期">
                </el-table-column>
                <!-- <el-table-column align="center" label="操作" width="80">
          <template slot-scope="scope">
            <el-button class="mr10 btnDel" type="text" @click="del(scope.row)"
              >清除记录</el-button
            >
          </template>
        </el-table-column> -->
            </template>
        </commonTable>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { selectAllPointRecord } from "@/api/walletRecords";
export default {
    name: "sendCardsTable",
    components: {
        commonTable,
    },
    data() {
        return {
            //列表配置
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            tableData: [{ name: "测试" }],
            searchData: { pointRecordName: "", pointRecordType: "all" },
            userInfo: this.$store.state.loginRoot.userInfo,
            accountType: "1"
        };
    },
    filters: {

    },
    created() {
        this.getList();
    },
    methods: {
        /**@method 搜索 */
        searchFun() {
            this.currentPage = 1;
            this.getList();
        },
        addUpdate() {
            //修改添加会员等级
            this.$refs.formData.validate((valid) => {
                if (valid) {

                }
            });
        },
        /**@method 获取列表 */
        async getList(payload) {
            let params = {
                accountType: this.accountType,
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                userName: this.searchData.name
            };
            if (this.searchData.pointRecordType != "all") {
                params.pointRecordType = this.searchData.pointRecordType;
            }
            if (this.searchData.pointRecordName) {
                params.pointRecordName = this.searchData.pointRecordName;
            }
            try {
                this.loading = true;
                let result = await selectAllPointRecord(params);
                this.loading = false;

                const { data } = result;
                let tableData = [];
                for (let row of data.pageInfo.list) {
                    tableData.push(row);
                }
                this.tableData = tableData;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
    },
};
</script>

<style lang="scss" scoped>
.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>